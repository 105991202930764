import React from 'react'
import PropTypes from 'prop-types'
import {Form} from 'react-final-form'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';

export default class ApplicationWizard extends React.Component {

    static propTypes = {
        onSubmit: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            values: props.initialValues || {}
        }
    }

    static Page = ({children}) => children

    next = values =>
        this.setState(state => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values
        }))

    previous = () =>
        this.setState(state => ({
            page: Math.max(state.page - 1, 0),
        }))

    /**
     * NOTE: Both validate and handleSubmit switching are implemented
     * here because 🏁 Redux Final Form does not accept changes to those
     * functions once the form has been defined.
     */
    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[
            this.state.page
            ]
        return activePage.props.validate ? activePage.props.validate(values) : {}
    }

    handleSubmit = values => {
        const {children, onSubmit} = this.props
        const {page} = this.state
        const isLastPage = page === React.Children.count(children) - 1
        if (isLastPage) {
            return onSubmit(values)
        } else {
            this.next(values)
        }
    }

    /*
     * https://material-ui.com/components/steppers/
     */
    render() {
        const {children} = this.props
        const {page, values} = this.state
        const activePage = React.Children.toArray(children)[page]
        const isLastPage = page === React.Children.count(children) - 1
        return (
            <div>
                <ThemeProvider theme={createMuiTheme({
                    palette: {
                        primary: {
                            main: '#125EA1',
                        },
                        secondary: {
                            main: '#0A4066',
                        },
                    },
                })}>
                    <Stepper activeStep={this.state.page} alternativeLabel>
                        {['Kontakt', 'Angebot', 'Situation', 'Erreichbarkeit', 'Absenden'].map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </ThemeProvider>
                <Form initialValues={values} validate={this.validate} onSubmit={this.handleSubmit}>
                    {({handleSubmit, submitting, values}) => (
                        <form name="applicationForm" id="applicationForm" method="POST" data-netlify="true"
                              data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
                            <div className="sr-only">
                                <label id="honeypot-label" htmlFor="honeypot">Don't fill this out if you're
                                    human:</label>
                                <input aria-labelledby="honeypot-label" id="honeypot" name="bot-field"/>
                            </div>
                            <input aria-labelledby="honeypot-label" type="hidden" name="form-name"
                                   value="applicationForm"/>
                            {activePage}
                            <div class="flex flex--middle flex--center flex--col-2">
                                {page > 0 && (
                                    <button type="button" class="btn btn-primary btn-wizard" onClick={this.previous}>
                                        « Zur&uuml;ck
                                    </button>
                                )}
                                {!isLastPage &&
                                <button class="btn btn-primary btn-wizard" type="submit">Weiter »</button>}
                                {isLastPage && (
                                    <button class="btn btn-primary btn-wizard" type="submit" disabled={submitting}>
                                        Jetzt anfragen!
                                    </button>
                                )}
                            </div>
                        </form>
                    )}
                </Form>
            </div>
        )
    }
}
