import React from 'react';
import {getPages} from "../utils";
import _ from "lodash";
import moment from "moment-strftime";

export default class ContactSection extends React.Component {
    render() {
        let posts_all = getPages(this.props.pageContext.pages, '/blog');
        let posts_sorted = _.orderBy(posts_all, 'frontmatter.date', "asc");
        let today = new Date();
        today.setHours(0,0,0,0);

        posts_sorted = posts_sorted.filter(function (value, index, arr) {
            if (!value.frontmatter || !value.frontmatter.categories)
                return false;
            let isKurs = value.frontmatter.categories.filter(function(category){
                return category.includes('/categories/kurse')
            });
            return isKurs;
        });
        posts_sorted = posts_sorted.filter(function (value, index, arr) {
            let post_date = moment(value.frontmatter.date);
            return post_date >= today;
        });
        return (
            <section className="section">
                <div className="container container--md">
                    <form name="contactForm" id="contactForm" method="POST" data-netlify="true"
                          data-netlify-honeypot="bot-field" action="/angemeldet">
                        <div className="sr-only">
                            <label id="honeypot-label" htmlFor="honeypot">Don't fill this out if you're human:</label>
                            <input aria-labelledby="honeypot-label" id="honeypot" name="bot-field"/>
                        </div>
                        <input aria-labelledby="honeypot-label" type="hidden" name="form-name" value="contactForm"/>

                        <p>Für welchen Kurs möchten Sie sich anmelden?</p>
                        <div className="form-group">
                            <label htmlFor="subject">Kurs</label>
                            <div className="form-select-wrap">
                                <select name="Kurs" id="kurs" required>
                                    <option value="">Bitte auswählen</option>
                                    {_.map(posts_sorted, (post) => {
                                        let kurs_name = post.frontmatter.title + ' - ' + moment(post.frontmatter.date).strftime('%d.%m.%Y');
                                        return (
                                            <option value={kurs_name}>{kurs_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <p><br/>Angaben zum Kind, das Sie anmelden möchten?</p>
                        <div className="form-group">
                            <label id="firstnamechild-label" htmlFor="firstnamechild">Vorname des Kindes</label>
                            <input aria-labelledby="firstnamechild-label" type="text" name="firstnamechild"
                                   id="firstnamechild" placeholder="Vorname des Kindes"/>
                        </div>
                        <div className="form-group">
                            <label id="namechild-label" htmlFor="namechild">Name des Kindes</label>
                            <input aria-labelledby="namechild-label" type="text" name="namechild" id="namechild"
                                   placeholder="Name des Kindes"/>
                        </div>
                        <div className="form-group">
                            <label id="age-label" htmlFor="age">Alter des Kindes</label>
                            <input aria-labelledby="age-label" type="text" name="age" id="age"
                                   placeholder="Alter des Kindes"/>
                        </div>
                        <p><br/>Ihre Angaben?</p>
                        <div className="form-group">
                            <label id="firstname-label" htmlFor="firstname">Vorname des Erziehungsberechtigten</label>
                            <input aria-labelledby="firstname-label" type="text" name="firstname" id="firstname"
                                   placeholder="Ihr Vorname" required/>
                        </div>
                        <div className="form-group">
                            <label id="name-label" htmlFor="name">Name des Erziehungsberechtigten</label>
                            <input aria-labelledby="name-label" type="text" name="name" id="name" placeholder="Ihr Name"
                                   required/>
                        </div>
                        <div className="form-group">
                            <label id="email-label" htmlFor="email">Email</label>
                            <input aria-labelledby="email-label" type="email" name="email" id="email"
                                   placeholder="Ihre Email Adresse" required/>
                        </div>
                        <div className="form-group">
                            <label id="telefon-label" htmlFor="telefon">Telefonnummer</label>
                            <input aria-labelledby="telefon-label" type="text" name="telefon" id="telefon"
                                   placeholder="Ihre Telefonnummer" required/>
                        </div>
                        <p>
                            Ich verwende Ihre personenbezogenen Daten nur, für die Durchführung des Kurses
                            und um Sie ggf. von Zeit zu Zeit über Angebote sowie andere Inhalte, die für
                            Sie interessant sein könnten, per E-Mail oder Telefon zu informieren.
                        </p>
                        <p>
                            Durch Ihre Zustimmung erteilen Sie mir hierzu die Erlaubnis und bestätigen, dass Sie
                            unsere Datenschutzerklärung gelesen und akzeptiert haben.
                        </p>
                        <div className="form-group form-checkbox">
                            <input aria-labelledby="consent-label" type="checkbox" name="consent" id="consent" required/>
                            <label id="consent-label" htmlFor="consent">Ich verstehe, dass dieses Formular meine Angaben
                                speichert, damit ich
                                kontaktiert werden kann.</label>
                        </div>
                        <div className="form-submit">
                            <button type="submit" className="button">Jetzt anmelden!</button>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}
