import React from 'react';
import _ from 'lodash';

import './TestimonialsSection.css';
import {withPrefix, markdownify} from '../utils';

export default class TestimonialsSection extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        return (
            <section className="section section--testimonials">
                {_.get(section, 'title', null) && (
                    <div className="container container--md align-center">
                        <h2 className="section__title">{_.get(section, 'title', null)}</h2>
                    </div>
                )}
                <div className="container container--lg">
                    <div className="flex flex--col-3">
                        {_.map(_.get(section, 'testimonials', null), (testimonial, testimonial_idx) => (
                            <div key={testimonial_idx} className="cell">
                                <div className="card testimonial">
                                    <div className="section__body card__body">
                                        {_.get(testimonial, 'content', null) && (
                                            <div className="section__copy card__body">
                                                {markdownify(_.get(testimonial, 'content', null))}
                                            </div>
                                        )}
                                        <div className="card__footer">
                                            <div className="photo">
                                                {_.get(testimonial, 'photo', null) && (
                                                    <img src={withPrefix(_.get(testimonial, 'photo', null))}
                                                         alt={_.get(testimonial, 'title', null)}
                                                         className="rounded-circle"/>
                                                )}
                                            </div>
                                            <div className="author">
                                                <div className="name">{_.get(testimonial, 'author', '')}</div>
                                                <div className="organization">{_.get(testimonial, 'organization', '')}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}
