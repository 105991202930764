import React from 'react';
import _ from 'lodash';

import {withPrefix, markdownify} from '../utils';

export default class ListSection extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        return (
            <section className="section section--list">
              {_.get(section, 'title', null) && (
              <div className="container container--md align-center">
                <h2 className="section__title">{_.get(section, 'title', null)}</h2>
              </div>
              )}
              <div className="container container--lg">
                <div className="flex flex--col-3">
                    {_.map(_.get(section, 'items', null), (listitem, listitem_idx) => (
                      <div key={listitem_idx} className="cell">
                        <div className="card team-member">
                          {listitem.photo && (
                          <figure className="card__media card__media--bottom">
                            <img src={withPrefix(listitem.photo)} alt={(listitem.title ? listitem.title : listitem.id)} />
                          </figure>
                          )}
                          <div className="card__body">
                            <header className="card__header">
                              <h3 className="h4 card__title">{listitem.title}</h3>
                            </header>
                            {listitem.content && (
                            <div className="card__copy">
                              {markdownify(listitem.content)}
                            </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </section>
        );
    }
}
