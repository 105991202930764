import React from 'react';
import {Field} from 'react-final-form'
import ApplicationWizard from "./ApplicationWizard";
import {navigate} from "gatsby-link";

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}


export default class ApplicationSection extends React.Component {

    required = value => (value ? undefined : 'Required')

    onSubmit = async values => {
        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({"form-name": "applicationForm", ...values})
        })
            .then((res) => navigate("/success/"))
            .catch(error => alert(error));
    }

    render() {
        return (
            <section className="section">
                <div className="container container--md">
                    <ApplicationWizard initialValues={{}} onSubmit={this.onSubmit}>
                        <ApplicationWizard.Page>
                            <div className="form-group">
                                <label id="firstname-label" htmlFor="firstName">Vorname</label>
                                <Field aria-labelledby="firstName-label" component="input" type="text" name="firstName"
                                       id="firstName"
                                       placeholder="Ihr Vorname" required validate={this.required}/>
                            </div>
                            <div className="form-group">
                                <label id="name-label" htmlFor="name">Name</label>
                                <Field aria-labelledby="name-label" component="input" type="text" name="name" id="name"
                                       placeholder="Ihr Name" required validate={this.required}/>
                            </div>
                            <div className="form-group">
                                <label id="email-label" htmlFor="email">E-Mail Adresse</label>
                                <Field aria-labelledby="email-label" component="input" type="email" name="email"
                                       id="email"
                                       placeholder="vorname.name@example.edu" required/>
                            </div>
                            <div className="form-group">
                                <label id="phone-label" htmlFor="email">Telefonnummer</label>
                                <Field aria-labelledby="phone-label" component="input" type="text" name="phone"
                                       id="phone"
                                       placeholder="+41 ... bzw 079 ..." required/>
                            </div>
                            <div className="sr-only">
                                <label htmlFor="offering-label">-</label>
                                <input type="hidden" name="offering" value="" aria-labelledby="offering-label"/>
                                <label htmlFor="motivation-label">-</label>
                                <input type="hidden" name="motivation" value="" aria-labelledby="motivation-label"/>
                                <label htmlFor="situation-label">-</label>
                                <input type="hidden" name="situation" value="" aria-labelledby="situation-label"/>
                                <label htmlFor="availability-label">-</label>
                                <input type="hidden" name="availability" value="" aria-labelledby="availability-label"/>
                                <label htmlFor="availability_other-label">-</label>
                                <input type="hidden" name="availability_other" value="" aria-labelledby="availability_other-label"/>
                                <label htmlFor="consent-label">-</label>
                                <input type="hidden" name="consent" value="" aria-labelledby="consent-label"/>
                            </div>
                        </ApplicationWizard.Page>
                        <ApplicationWizard.Page>
                            <div className="form-group">
                                <label id="offering-label" htmlFor="offering">Für welches Angebot interessieren Sie sich?</label>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="ueberwinderkinderschule">
                                        <Field className="form-check-input" component="input" type="checkbox"
                                               name="offering" id="ueberwinderkinderschule" value="Überwinderkinder Kurse für die Schule" required/>
                                        Überwinderkinder Kurse für die Schule
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="ueberwinderkinderclub">
                                        <Field className="form-check-input" component="input" type="checkbox"
                                               name="offering" id="ueberwinderkinderclub" value="Überwinderkinder Kurse für Vereine und andere Organisationen"/>
                                        Überwinderkinder Kurse für Vereine und andere Organisationen
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="elternabend">
                                        <Field className="form-check-input" component="input" type="checkbox"
                                               name="offering" id="elternabend" value="Elternabend oder Elternfortbildungsanlass"/>
                                        Elternabend oder Elternfortbildungsanlass
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="lehrerfortbildung">
                                        <Field className="form-check-input" component="input" type="checkbox"
                                               name="offering" id="lehrerfortbildung" value="Lehrerfortbildung"/>
                                        Lehrerfortbildung
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="impulsreferat">
                                        <Field className="form-check-input" component="input" type="checkbox"
                                               name="offering" id="impulsreferat" value="Impulsreferat oder Vortrag an einem von Ihnen organisierten Anlass"/>
                                        Impulsreferat oder Vortrag an einem von Ihnen organisierten Anlass
                                    </label>
                                </div>
                            </div>
                        </ApplicationWizard.Page>
                        <ApplicationWizard.Page>
                            <div className="form-group">
                                <label id="motivation-label" htmlFor="motivation">Was ist Ihre Motivation und
                                    Ihr Hauptanliegen für die Buchung eines meiner Angebote?</label>
                                <Field aria-labelledby="motivation-label" component="textarea"
                                       name="motivation" id="motivation" rows="5"
                                       placeholder=""/>
                            </div>
                            <div className="form-group">
                                <label id="situation-label" htmlFor="situation">Beschreiben Sie Ihre aktuelle
                                    Situation?</label>
                                <Field aria-labelledby="situation-label" component="textarea"
                                       name="situation" id="situation" rows="5"
                                       placeholder="" required/>
                            </div>
                        </ApplicationWizard.Page>
                        <ApplicationWizard.Page>
                            <div className="form-group text-left">
                                <label id="availability-label" htmlFor="availability">Wann kann ich Sie telefonisch am Besten erreichen?</label>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="monday">
                                        <Field className="form-check-input" component="input" type="radio" name="availability"
                                               id="monday" value="Montag Nachmittag (13.45 - 15.30 Uhr)" required/>
                                        Montag Nachmittag (13.45 - 15.30 Uhr)
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="tuesday">
                                        <Field className="form-check-input" component="input" type="radio" name="availability"
                                               id="tuesday" value="Dienstag Vormittag (9.00 - 11.00 Uhr)" required/>
                                        Dienstag Vormittag (9.00 - 11.00 Uhr)
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="wednesday">
                                        <Field className="form-check-input" component="input" type="radio" name="availability"
                                               id="wednesday" value="Mittwoch Vormittag (9.00 - 11.00 Uhr)" required/>
                                        Mittwoch Vormittag (9.00 - 11.00 Uhr)
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="thursday">
                                        <Field className="form-check-input" component="input" type="radio" name="availability"
                                               id="thursday" value="Donnerstag Nachmittag (13.45 - 15.30 Uhr)" required/>
                                        Donnerstag Nachmittag (13.45 - 15.30 Uhr)
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="availability_other">
                                        <Field className="form-check-input" component="input" type="radio" name="availability"
                                               id="other" value="Erreichbarkeit: andere: " required/>
                                        Andere: <Field className="form-control-sm" component="input" type="text"
                                                              name="availability_other" id="availability_other"/>
                                    </label>
                                </div>
                            </div>
                        </ApplicationWizard.Page>
                        <ApplicationWizard.Page>
                            <h3>Datenschutz-Hinweis</h3>
                            <p>
                                Ich verwende Ihre personenbezogenen Daten nur, um das kostenlose Beratungsgespräch
                                durchzuführen und Sie ggf. von Zeit zu Zeit über Angebote sowie andere Inhalte, die für
                                Sie interessant sein könnten, per E-Mail oder Telefon zu informieren.
                            </p>
                            <p>
                                Durch Ihre Zustimmung erteilen Sie mir hierzu die Erlaubnis und bestätigen, dass Sie
                                unsere Datenschutzerklärung gelesen und akzeptiert haben.
                            </p>
                            <div className="form-group form-checkbox">
                                <Field aria-labelledby="consent-label" component="input" type="checkbox" name="consent" id="consent" required/>
                                <label id="consent-label" htmlFor="consent">Ja, ich stimme zu und möchte an der
                                    kostenlosen Beratung teilnehmen.</label>
                            </div>
                        </ApplicationWizard.Page>
                    </ApplicationWizard>
                </div>
            </section>
        );
    }
}
