import React from 'react';
import _ from 'lodash';

import {classNames, withPrefix} from '../utils';
import SectionActions from './SectionActions';

export default class CtaSection extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        return (
            <section className="section section--cta">
                <div className="container container--lg">
                    <div className={classNames('section__body', 'align-center', {
                        'inverse bg-blue': _.get(section, 'has_background', null) && (_.get(section, 'background_color', null) === 'blue'),
                        'bg-gray': _.get(section, 'has_background', null) && (_.get(section, 'background_color', null) === 'gray')
                    })}>
                        <div className="container container--md">
                            <div className={classNames('flex', 'flex--middle', 'flex--center', 'flex--col-2', {'align-center': _.get(section, 'align', null) === 'center', 'align-right': _.get(section, 'align', null) === 'right'})}>
                                {_.get(section, 'image', null) && (
                                    <div className={classNames('cell', 'section__media', {'section__media--right': _.get(section, 'image_position', null) === 'right'})}>
                                        <img src={withPrefix(_.get(section, 'image', null))} alt={_.get(section, 'title', null)} />
                                    </div>
                                )}
                                <div className="cell section__body">
                                    {_.get(section, 'title', null) && (
                                        <h2 className="section__title">{_.get(section, 'title', null)}</h2>
                                    )}
                                    {_.get(section, 'subtitle', null) && (
                                        <div className="section__copy">
                                            <p>{_.get(section, 'subtitle', null)}</p>
                                        </div>
                                    )}
                                    {_.get(section, 'actions', null) && (
                                        <div className="section__actions btn-group">
                                            <SectionActions {...this.props} actions={_.get(section, 'actions', null)}/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
